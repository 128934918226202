<template>
<section class="domain_content">
    <h1>
        Download GB Whatsapp Latest Version for iPhone Users
    </h1>

	<p>
		Actually, users can't download <a href="/">GB WhatsApp</a> directly on Apple Store, since it's a mod app based on the official WhatsApp. However, you can download and install GB WhatsApp IOS X Android on your phone. 
	</p>
	<p>
        But it's important to note that this version only replicates the iOS interface; it doesn't mean you can run GB WhatsApp on an iPhone. 
	</p>
	<p>
		Actually, iPhone users have one way to install an alternative of GB WhatsApp. But it requires jailbreaking the device. Jailbreaking is a complex process and comes with significant risks, such as security vulnerabilities and voiding your warranty. Therefore, we do not recommend this method for installing GB WhatsApp alternatives on iOS.
	</p>

    <h2>
        Download GB WhatsApp For Android
    </h2>

    <div class="old-version-column">
        <div class="version-item">
            <div class="old-version-row">
                <img src="@/assets/logo.webp" class="head-img" alt="logo" />
                <div class="version-detail">
                    <h4>GB WhatsApp 18.10</h4>
                    <p><strong>Size:</strong> 77MB</p>
                    <p><strong>Last Updated:</strong> 2 Days ago</p>
                    <p><strong>Downloads:</strong> 1,000,000+</p>
                    <p><strong>System:</strong> Android</p>
                </div>
            </div>
            <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
                <img :src="downloadicon" alt="download" class="download-img">
                <div class="download-name">
                    Download
                </div>
            </div>
        </div>

    </div>

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';

export default {
    ...{
        "metaInfo": {
            "title": "GB Whatsapp for iPhone|Download Latest Version 2024",
            "meta": [{
                "name": "description",
                "content": "GB WhatsApp for iPhone 2024 has updated a new version. Download the latest version to enjoy more custom features than in normal WhatsApp."
            }, {
                "name": "title",
                "content": "GB Whatsapp for iPhone|Download Latest Version 2024"
            }, {
                "property": "og:title",
                "content": "GB Whatsapp for iPhone|Download Latest Version 2024"
            }, {
                "property": "og:description",
                "content": "GB WhatsApp for iPhone 2024 has updated a new version. Download the latest version to enjoy more custom features than in normal WhatsApp."
            }],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.com/gbwhatsapp-for-iphone/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },

        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },

        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
